
import {
    apiShopLists,
    apiShopSwitchStatus,
    apiShopDelete,
    apiChangeRemark,
    apiSetUserWallet,
    apiSettingsPlatformGetPointConfig,
    apiSettingsPlatformSetPointConfig,
    apiBalanceList,
    apiBnwChangeType
} from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging, timeFormat } from '@/utils/util'
import LsUserChange from '@/components/ls-user-change.vue'

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
        LsUserChange
    }
})
export default class ShopList extends Vue {
    /** S Data **/

    apiShopLists = apiShopLists
    timeFormat = timeFormat
    pager: RequestPaging = new RequestPaging()
    balancePager: RequestPaging = new RequestPaging()

    searchObj: Object = {
        status: '',
        shop_info: '',
        create_time: ''
    }
    balanceForm: any = {
        action: 1,
        num: 0,
        remark: '',
        type: 1,
        id: 0,
        value: 0
    }
    balanceShow = false

    balanceRecordShow = false
    balanceInfo = {
        type: '',
        action: '',
        start_time: '',
        end_time: '',
        sid: ''
    }
    balanceChangeTypeList = []
    tableData = []

    pointForm = {
        sid: 0,
        make_video: 0,
        body_seg: 0,
        selfie_anime: 0,
        ai_img: 0,
        ai_timg: 0,
        roduction_img: 0
    }
    pointShow = false
    pointRules = {
        make_video: [{ required: true, message: '请输入制作视频能量点', trigger: ['blur', 'change'] }],
        body_seg: [{ required: true, message: '请输入人像分割能量点', trigger: ['blur', 'change'] }],
        selfie_anime: [{ required: true, message: '请输入动漫画能量点', trigger: ['blur', 'change'] }],
        ai_img: [{ required: true, message: '请输入文生图能量点', trigger: ['blur', 'change'] }],
        ai_timg: [{ required: true, message: '请输入图生图能量点', trigger: ['blur', 'change'] }],
        roduction_img: [{ required: true, message: '请输入稿件生产', trigger: ['blur', 'change'] }],
    }
    // 修改后的值
    get lastValue(): number {
        let total = this.balanceForm.value
        if (this.balanceForm.action == 1) {
            total = Number(this.balanceForm.value) + this.balanceForm.num * 1
        } else {
            total = Number(this.balanceForm.value) - this.balanceForm.num * 1
        }
        return total
    }
    /** E Data **/

    /** S Methods **/
    // 处理时间
    splitTime() {
        if (this.tableData != null) {
            this.balanceInfo.start_time = this.tableData[0]
            this.balanceInfo.end_time = this.tableData[1]
        } else {
            this.balanceInfo.start_time = ''
            this.balanceInfo.end_time = ''
        }
        this.getBalanceLogList(1);
    }

    // 变动类型数据
    getSearchList() {
        apiBnwChangeType({}).then((res: any) => {
            this.balanceChangeTypeList = res
        })
    }
    // 获取余额记录
    getBalanceLogList(page?: number): void {
        page && (this.balancePager.page = page)
        this.balancePager
            .request({
                callback: apiBalanceList,
                params: {
                    ...this.balanceInfo
                }
            })
            .then((res: any) => { })
    }

    //获取商户能量点
    async getPointConfig() {
        const res = await apiSettingsPlatformGetPointConfig({
            sid: this.pointForm.sid
        })
        console.log(res.data)
        Object.keys(res.data).map((key) => {
            this.$set(this.pointForm, key, res.data[key])
        })
    }

    // 获取商城列表
    getShopLists(): void {
        this.pager.request({
            callback: apiShopLists,
            params: {
                ...this.searchObj
            }
        })
    }

    processInput(input: any) {
        if (typeof input === 'string' && input.includes('{') && input.includes('}')) {
            try {
                const obj = JSON.parse(input);
                return obj.zh_cn;
            } catch (error) {
                return input;
            }
        } else if (typeof input === 'string') {
            return input
        } else {
            return 'Invalid input';
        }
    }

    // 修改商城状态
    async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
        await apiShopSwitchStatus({ id })
        this.getShopLists()
    }

    async changeRemarks(text: string, id: number): Promise<void> {
        await apiChangeRemark({ id: id, remark: text })
        this.getShopLists()
    }

    // 删除商城
    async onShopDelete(row: any): Promise<void> {
        await apiShopDelete({ id: row.id })
        this.getShopLists()
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map(key => {
            this.$set(this.searchObj, key, '')
        })
        this.getShopLists()
    }

    // 去编辑商城
    toShopEdit(id: number | any): void {
        this.$router.push({
            path: '/shop/lists_detail',
            query: {
                id: id
            }
        })
    }


    // 去编辑商城等级
    toShopLevel(id: number | any): void {
        this.$router.push({
            path: '/shop/merchant_level',
            query: {
                id: id
            }
        })
    }

    toOpenBuildImg(row: any) {
        let url = `https://diy.fqwlkj.cn/create/index?shop_sn=${row.sn}&title=${escape(encodeURIComponent(row.name))}`
        window.open(url, '_blank')
    }

    // 修改超级管理员
    toSuperAdminUpdate(id: number | any): void {
        this.$router.push({
            path: '/shop/modify_account',
            query: {
                id: id
            }
        })
    }

    // 模型管理
    toModelManage(id: number | any): void {
        this.$router.push({
            path: '/shop/model',
            query: {
                id: id
            }
        })
    }

    // 去商家后台
    toShop(url: any, status: number) {
        if (status == 0) return this.$message.error('该商城暂未营业中')

        if (url.indexOf('https://') === 0) window.open(url)
        else if (url.indexOf('http://') === 0) window.open(url)
        else window.open('http://' + url)
    }

    //设置商家能量点
    updatePoint() {
        // 验证表单格式是否正确
        const refs = this.$refs['pointForm'] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return
            apiSettingsPlatformSetPointConfig({
                sid: this.pointForm.sid,
                data: {
                    ...this.pointForm
                }
            }).then(res => {
                this.$message.success('设置成功');
                this.pointShow = false;
            })

        })
    }

    updateStoreWallet() {
        apiSetUserWallet(this.balanceForm).then(res => {
            this.balanceShow = false
            this.getShopLists()
        })
    }
    /** E Methods **/

    /** S ods **/
    created() {
        this.getShopLists()
    }
}
